<template>
    <b-modal size="lg" id="checked-barang-modal">
        <b-table
            striped
            small
            hover
            responsive
            :fields="fields"
            :items="stocks"
        >
            <template #head(checkbox)>
                <b-form-checkbox v-model="allChecked"></b-form-checkbox>
            </template>
            <template #cell(checkbox)="{item}">
                <b-form-checkbox v-model="checkedBarang" :value="item"></b-form-checkbox>
            </template>
            <template #cell(kategori)="{item}">
                {{ item.kategori ? item.kategori.kategori : '-' }}
            </template>
            <template #cell(satuan)="{item}">
                {{ item.satuan ? item.satuan.satuan : '-' }}
            </template>
            <template #cell(stocks)="{item}">
                {{ getTotal(item.stocks) }}
            </template>
        </b-table>
        <template #modal-footer>
            <div class="d-flex justify-content-end">
                <b-button variant="danger" :disabled="checkedBarang.length < 1" @click.prevent="removeAll">Hapus</b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BTable, BFormCheckbox, BButton} from 'bootstrap-vue'
export default {
    props: ['stocks'],
    components: {
        BModal, BTable, BFormCheckbox, BButton
    },
    watch: {
        allChecked(val) {
            if(val) {
                this.checkedBarang = this.stocks
            }
            else {
                this.checkedBarang = []
            }
        }
    },
    data: () => ({
        allChecked: false,
        fields: [
            { key: "checkbox", label: "#" },
            { key: "nama", label: "Nama Barang", sortable: true },
            { key: "kategori", label: "Kategori", sortable: true },
            { key: "satuan", label: "Satuan" },
            { key: "stocks", label: "Jumlah Stok", sortable: true },
            { key: "qty_request", label: "Request"}
        ],
        checkedBarang: [] // checked barang yang mau dihapus
    }),
    methods: {
        removeAll() {
            let stocks = Object.assign([], this.stocks)
            const stocksFinal = []

            stocks.map(stock => {
                const find = this.checkedBarang.find(st => st.id == stock.id)
                if(!find) {
                    stocksFinal.push(stock)
                }
            })
            this.$emit('removeAll', stocksFinal)
            this.checkedBarang = []
        },
        getTotal(stocks) {
            return stocks.length < 1 ? 0 : stocks.reduce((total, item) => total += item.jumlah, 0)
        }
    }
}
</script>